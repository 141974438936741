<template>
  <span v-if="fullname.length > 0">
    <b-button variant="success" disabled>
      <b-icon icon="person-square"></b-icon> {{ fullname }}
    </b-button>
  </span>
</template>

<script>
export default {
  data() {
    return {
      fullname: this.$store.getters.getFullname,
    };
  },
};
</script>

<style>
</style>