import Vue from 'vue'
import Vuex from 'vuex'
import cookies from "js-cookie"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

    defaultDomainUrl: "",
    isAuthenticated: "",
    fullname: "",
    userName: "",
    initials: "",
  },
  getters: {
    getDefaultDomainUrl(state) {

      return state.defaultDomainUrl
    },
    isUserAuthenticated(state) {
      let domSessionId = cookies.get("DomSessionId");
      console.log("Store domSessionId ", domSessionId)
      if (domSessionId != undefined) {
        state.isAuthenticated = true;
      }
      return state.isAuthenticated;
    },
    getFullname(state) {
      let fullname = localStorage.fullname
      if (fullname != undefined) {
        state.fullname = fullname;
      }
      console.log("store getFullname ", state.fullname)
      return state.fullname;
    },
    getInitials(state) {
      let initials = localStorage.initials
      if (initials != undefined) {
        state.initials = initials;
      }
      console.log("store getInitials ", state.initials)
      return state.fullname;
    },
    getUsername(state){
      let username = localStorage.username
      if (username != undefined) {
        state.username = username;
      }
      console.log("store getUsername ", state.username)
      
      return state.username
    }

  },
  mutations: {

    setDefaultDomainUrl(state) {
      let test = window.location.host.substring(window.location.host.lastIndexOf("."))
      
      switch (test) {
        case ".dev":
          state.defaultDomainUrl = "https://api-dev.metz.dk";
          break
        case ".dk":
          state.defaultDomainUrl = "https://api.metz.dk";
          break
        default:
          if (process.env.NODE_ENV == "development" && state.defaultDomainUrl === "") {
            state.defaultDomainUrl = "https://api-dev.metz.dk";
          } else {
            state.defaultDomainUrl = "https://api.metz.dk";
          }
        }
        console.log("store setDefaultDomainUrl",state.defaultDomainUrl)
    },
    isUserAuthenticated(state, value) {
      state.isAuthenticated = value;
    },
    setFullname(state, fullname) {
      state.fullname = fullname;
      console.log("store setFullname ", state.fullname)
    },
    setUsername(state, username) {
      state.userName = username;
    },
    setInitials(state, initials) {
      state.initials = initials;
    }


  },
  actions: {

  },

})