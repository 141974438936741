<template>
  <div></div>
</template>
<script>

import cookies from "js-cookie";

export default {
  methods: {
    resetCookie: function () {
      // this.$cookies.remove("DomSessionId");
      cookies.remove("DomSessionId");
      localStorage.fullname = ""
      localStorage.initials = ""
      localStorage.username = ""
    },
  },
};
</script>