import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index'

// import Orders from '../views/Orders.vue';
// import PickOrder from '../components/pickorders/PickOrder.vue'
// import { createRouter } from "vue-router";

// import {
//   globalData
// } from "../main.js";

Vue.use(VueRouter);


const routes = [{
    path: "/app",
    name: "App",
    component: () => import("../App.vue"),
  },

  {
    path: "/",
    name: "home",
    component: () => import("../views/Orders.vue"),
  },

  {
    path: '/orders',
    name: 'Orders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Orders.vue')
    /* webpackChunkName: "about" */
  },
  {
    path: '/stock',
    name: 'Stock',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Stock.vue')
    /* webpackChunkName: "about" */
  },

  {
    path: '/UserLogin',
    name: 'UserLogin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "register" */ '../views/Login_two_factor.vue'),
    // meta: {
    //   requireAuth: true
    // } //the route requires authentication

  },

  {
    path: '/pickorder',
    name: 'Pickorder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "Pickorder" */ '../components/pickorders/PickOrder.vue')



  },
  
  {
    path: '/pincodeauth',
    name: 'PincodeAuth',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "Pickorder" */ '../components/user/PincodeAuth.vue')



  },

  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "Login" */ '../views/Login.vue')

  },

];


const router = new VueRouter({
  routes
});


export default router;

router.beforeEach((to, from, next) => {
  // console.log("router to", to)
  // console.log("router from", from)

  if (store.getters.isUserAuthenticated == false) {
    if (to.path !== '/login') {
      // console.log("router 1")
      if (to.path == '/UserLogin') {
        // console.log("router 1.1")
        next()
      } else {
        console.log("router 1.2")
        next({
          name: "Login",
        });
      }

    } else {
      // console.log("router 2")
      next();

    }
  } else {
    // console.log("router 3")
    next();
  }


})