<template>
  <div id="app">
    <b-container fluid>
      <b-navbar  toggleable="sm" type="dark" variant="success">
        <b-navbar-brand
          >Metz Giftshop web pluk1
          <user-badge :key="userBadgeRefresh"></user-badge>
        </b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item active to="Orders">Ordre</b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav>
            <b-nav-item active to="Stock">Lager</b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav>
            <b-nav-item active to="UserLogin">Login to faktor</b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav>
            <user-logout ref="myUserLogOut"></user-logout>
            <b-nav-item @click="logOut">Log ud</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>

      <!-- her we start the router <keep-Alive> tag ensures that the search still is there when we navigate back from picklist-->
      <keep-alive include="Orders"
        ><router-view></router-view
      ></keep-alive>
      <!-- <keep-alive include="Orders"></keep-alive> -->
    </b-container>
  </div>
</template>

<script>

import UserBadge from "./components/user/UserBadge.vue";
import UserLogout from "./components/user/UserLogout.vue";

export default {
  components: {
    UserBadge,
    UserLogout,
  },
  watch: {
    $route(to) {
      // console.log("app route to", to);
      // console.log("app route from", from);
      if (to.name === "Orders") {
        this.userBadgeRefresh++;
      }
    },
  },

  data() {
    return {
      domSessionId: "",
      username: localStorage.username,
      fullname: this.$store.getters.getFullname,
      userBadgeRefresh: 0,
    };
  },
  methods: {
    logOut: function () {
      this.$refs.myUserLogOut.resetCookie();
      localStorage.fullname = "";
      this.fullname = "Logget ud";
      this.$router.go(0);
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

/* input {
  margin-right: 20px;
} */
</style>
